var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "section",
      { staticClass: "w-full" },
      [
        _c("section-header", {
          scopedSlots: _vm._u(
            [
              {
                key: "icon",
                fn: function () {
                  return [_vm._t("header-icon")]
                },
                proxy: true,
              },
              {
                key: "title",
                fn: function () {
                  return [
                    _vm._t("header-title", function () {
                      return [_c("breadcrumb", { staticClass: "flex flex-1" })]
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "right",
                fn: function () {
                  return [_vm._t("header-right")]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        }),
        _vm._t("before-status"),
        _c(
          "transition",
          { attrs: { name: "slide" } },
          [
            _vm.status !== _vm.okStatus
              ? _vm._t("status", function () {
                  return [
                    _c("section-status", { attrs: { status: _vm.status } }),
                  ]
                })
              : _vm._e(),
          ],
          2
        ),
        _vm._t("after-status"),
        _c(
          "div",
          [
            _vm._t("content", function () {
              return [_vm._v(" No content found ")]
            }),
          ],
          2
        ),
        _c("overlay-loader", { attrs: { "is-loading": _vm.isLoading } }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }