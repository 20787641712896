<template>
  <div class="bg-white shadow-md rounded-lg mb-4">
    <sun-alert v-if="status === error" :dismissible="false" variant="error" data-testid="section-status-error">
      <span slot="title" class="flex"> <warning-svg class="w-6 mr-2"></warning-svg> Error found </span>
      <span slot="description"> Something happened retrieving data from our services. Try again later</span>
    </sun-alert>

    <sun-alert v-else-if="status === empty" :dismissible="false" variant="warn" data-testid="section-status-empty">
      <span slot="title" class="flex"><span class="w-6 mr-2">:(</span> 0 results found</span>
      <span slot="description">
        We couldn't find any results from your search criteria. Try again using different filters or search terms
      </span>
    </sun-alert>

    <sun-alert v-else :dismissible="false" variant="error" data-testid="section-status-error">
      <span slot="title" class="flex"> <warning-svg class="w-6 mr-2"></warning-svg> Error found </span>
      <span slot="description"> Something happened retrieving data from our services. Try again later</span>
    </sun-alert>

    <p class="p-4 text-left"><span class="font-bold">You still might be interested in</span> results shown below</p>
  </div>
</template>

<script>
import WarningSvg from '@/components/icons/WarningSvg';
import { SECTION_STATUS } from '@/model/shared/sectionStatus';

export default {
  name: 'SectionStatus',
  components: {
    WarningSvg,
  },
  props: {
    status: {
      type: String,
      default: () => SECTION_STATUS.OK,
      validator: status => {
        return Object.values(SECTION_STATUS).includes(status);
      },
    },
  },
  data() {
    return {
      error: SECTION_STATUS.ERROR,
      empty: SECTION_STATUS.EMPTY,
    };
  },
};
</script>
