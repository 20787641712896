<template>
  <div>
    <section class="w-full">
      <section-header>
        <template #icon>
          <slot name="header-icon" />
        </template>

        <template #title>
          <slot name="header-title">
            <breadcrumb class="flex flex-1" />
          </slot>
        </template>

        <template #right>
          <slot name="header-right" />
        </template>
      </section-header>

      <slot name="before-status"> </slot>

      <transition name="slide">
        <slot v-if="status !== okStatus" name="status">
          <section-status :status="status" />
        </slot>
      </transition>

      <slot name="after-status"> </slot>

      <div>
        <slot name="content"> No content found </slot>
      </div>
      <overlay-loader :is-loading="isLoading"></overlay-loader>
    </section>
  </div>
</template>

<script>
import SectionHeader from '@/components/atoms/SectionHeader';
import { SECTION_STATUS } from '@/model/shared/sectionStatus';
import SectionStatus from '@/components/molecules/shared/SectionStatus';
import Breadcrumb from '@/components/atoms/Breadcrumb';
import OverlayLoader from '@/components/molecules/shared/OverlayLoader.vue';

export default {
  name: 'AsterixSection',
  components: {
    OverlayLoader,
    Breadcrumb,
    SectionHeader,
    SectionStatus,
  },
  props: {
    isLoading: { type: Boolean, default: () => false },
    status: {
      type: String,
      default: () => SECTION_STATUS.OK,
      validator: status => {
        return Object.values(SECTION_STATUS).includes(status);
      },
    },
  },
  data() {
    return {
      okStatus: SECTION_STATUS.OK,
    };
  },
};
</script>

<style scoped>
.slide-enter-active {
  -moz-transition-duration: 0.1s;
  -webkit-transition-duration: 0.1s;
  -o-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.1s;
  -webkit-transition-duration: 0.1s;
  -o-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
