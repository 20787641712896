<template>
  <section>
    <div v-if="isLoading" :class="{ overlay: isOverlay }">
      <loading></loading>
    </div>
  </section>
</template>

<script>
import Loading from '@/components/atoms/LogoLoading';

export default {
  name: 'OverlayLoader',
  components: {
    Loading,
  },
  props: {
    isOverlay: { type: Boolean, default: () => true },
    isLoading: { type: Boolean, default: () => false },
  },
};
</script>

<style scoped>
.overlay {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(237, 242, 247, 0.3);
  z-index: 1001;
}
:deep(.spinner) {
  border-color: #d5d5d5 white !important;
}
</style>
