var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bg-white shadow-md rounded-lg mb-4" },
    [
      _vm.status === _vm.error
        ? _c(
            "sun-alert",
            {
              attrs: {
                dismissible: false,
                variant: "error",
                "data-testid": "section-status-error",
              },
            },
            [
              _c(
                "span",
                {
                  staticClass: "flex",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [
                  _c("warning-svg", { staticClass: "w-6 mr-2" }),
                  _vm._v(" Error found "),
                ],
                1
              ),
              _c(
                "span",
                { attrs: { slot: "description" }, slot: "description" },
                [
                  _vm._v(
                    " Something happened retrieving data from our services. Try again later"
                  ),
                ]
              ),
            ]
          )
        : _vm.status === _vm.empty
        ? _c(
            "sun-alert",
            {
              attrs: {
                dismissible: false,
                variant: "warn",
                "data-testid": "section-status-empty",
              },
            },
            [
              _c(
                "span",
                {
                  staticClass: "flex",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [
                  _c("span", { staticClass: "w-6 mr-2" }, [_vm._v(":(")]),
                  _vm._v(" 0 results found"),
                ]
              ),
              _c(
                "span",
                { attrs: { slot: "description" }, slot: "description" },
                [
                  _vm._v(
                    " We couldn't find any results from your search criteria. Try again using different filters or search terms "
                  ),
                ]
              ),
            ]
          )
        : _c(
            "sun-alert",
            {
              attrs: {
                dismissible: false,
                variant: "error",
                "data-testid": "section-status-error",
              },
            },
            [
              _c(
                "span",
                {
                  staticClass: "flex",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [
                  _c("warning-svg", { staticClass: "w-6 mr-2" }),
                  _vm._v(" Error found "),
                ],
                1
              ),
              _c(
                "span",
                { attrs: { slot: "description" }, slot: "description" },
                [
                  _vm._v(
                    " Something happened retrieving data from our services. Try again later"
                  ),
                ]
              ),
            ]
          ),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p-4 text-left" }, [
      _c("span", { staticClass: "font-bold" }, [
        _vm._v("You still might be interested in"),
      ]),
      _vm._v(" results shown below"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }