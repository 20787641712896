var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _vm.isLoading
      ? _c("div", { class: { overlay: _vm.isOverlay } }, [_c("loading")], 1)
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }